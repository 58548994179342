import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Flex, Box, Text, useToast, Select } from "@chakra-ui/react";

import { encodeRFC3986PlusURIComponent } from "../../../../../utils/encodeImageURI";
import { getEmailId } from "../../../../../utils/localStorageIndex.js";
import { toastFunctionToaster } from "../../../../../utils/toastFunction.js";
import {
  setWorkFlowImageData,
  setWorkflowImgCounter,
  setWorkFlowEngineLoader,
  setWorkFlowEngineQueue,
} from "../../../../../store/actions/workFlowAction.js";

import {
  CollectGenerateStatus,
  ProjectWorkflowGenerate,
} from "../../../../../services/projectTemplateService.js";
import BatchGeneration from "./BatchGeneration.js";
import { stabilityAspectRatios } from "../../../../../constants/Workflow.js";

function GenerateAIImage() {
  const toast = useToast();
  const email = getEmailId();
  const dispatch = useDispatch();
  const playGroundDetails = useSelector((store) => store.workflowDesignHeader);
  const playGroundData = playGroundDetails?.workflowObject;
  const generateTimer = useRef(0);

  const generateRegions = () => {
    const regions = playGroundData?.maskData
      .slice(0, 4)
      ?.map((data, index) =>
        data
          ? {
              prompt: playGroundData?.promptData[index],
              negative_prompt: playGroundData?.negativePrompt,
              mask: data,
              ...(playGroundData.reference_name[index]?.length
                ? {
                    ref_image: encodeRFC3986PlusURIComponent(playGroundData.reference_name[index]),
                  }
                : {}),
            }
          : null
      )
      .filter((data) => data);
    if (
      playGroundData.modelCode === 1 ||
      playGroundData.modelCode === 3 ||
      playGroundData.modelCode === 4
    ) {
      return [
        ...regions,
        {
          prompt: playGroundData?.promptData[4],
          negative_prompt: playGroundData?.negativePrompt,
          mask: { size: [1, 1], counts: "01" },
          ...(playGroundData.reference_name[4]?.length
            ? {
                ref_image: encodeRFC3986PlusURIComponent(playGroundData.reference_name[4]),
              }
            : {}),
        },
      ];
    }
    if (regions?.length === 0) {
      // Background region
      return [
        {
          prompt: playGroundData?.promptData[0],
          negative_prompt: playGroundData?.negativePrompt,
          mask: { size: [1, 1], counts: "01" },
          ...(playGroundData.reference_name[0]?.length
            ? {
                ref_image: encodeRFC3986PlusURIComponent(playGroundData.reference_name[0]),
              }
            : {}),
        },
      ];
    }
    return regions;
  };

  const generateCorbuParams = () => {

    let obj = {
      guidance_scale: playGroundData?.guidanceScale,
      alpha_sharpness: playGroundData?.alphaSharpness,
      num_inference_steps: playGroundData?.inferenceSteps,
      num_images_per_prompt: playGroundData?.generatedImageCount,
    };

    if (playGroundData.modelCode === 1) {
      obj["images"] = [
        {
          output_url: encodeRFC3986PlusURIComponent(playGroundData?.object_name),
          regions: generateRegions(),
        },
      ];
    } else {
      obj["images"] = [
        {
          image: encodeRFC3986PlusURIComponent(playGroundData?.object_name),
          regions: generateRegions(),
        },
      ];
      if (playGroundData?.control_guidance_image_name?.length) {
        obj["images"][0] = {
          ...obj["images"][0],
          control_image: encodeRFC3986PlusURIComponent(playGroundData.control_guidance_image_name),
          control_image_box: playGroundData.controlBoundingBox,
        }
      }
    }

    if (
      playGroundData.reference_name?.some((v) => v?.length) ||
      playGroundData.neg_reference_name?.length
    ) {
      obj["ref_adain_conditioning_scale"] = playGroundData?.refAdainScale;
      obj["ref_attn_conditioning_scale"] = playGroundData?.refAttnScale;
    }

    if (
      playGroundData?.control_guidance_image_name?.length
    ) {
      obj["controlnet_type"] = playGroundData?.guidanceType;
      obj["controlnet_conditioning_scale"] = playGroundData?.conditionScale;
    }

    if (playGroundData.modelCode === 1) {
      obj["model_sub_name"] = "text_to_image";
      obj["height"] = playGroundData?.imageHeight;
      obj["width"] = playGroundData?.imageWidth;
    } else if (
      playGroundData.modelCode === 2 ||
      playGroundData.modelCode === 3
    ) {
      obj["model_sub_name"] = playGroundData?.control_guidance_image_name?.length
        ? "inpaint_controlnet"
        : "diffedit";
      obj["inpaint_strength"] = playGroundData?.inpaintStrength;
      obj["mask_crop_padding"] = playGroundData?.maskCropPadding;
    } else if (playGroundData.modelCode === 4) {
      obj["model_sub_name"] = "controlnet";
      obj["controlnet_type"] = playGroundData?.guidanceType;
      obj["controlnet_conditioning_scale"] = playGroundData?.conditionScale;
    } else if (playGroundData.modelCode === 5) {
      obj["model_sub_name"] = "inpaint_controlnet";
      obj["controlnet_type"] = "refine";
      obj["controlnet_conditioning_scale"] = playGroundData?.conditionScale;
      obj["inpaint_strength"] = playGroundData?.inpaintStrength;
      obj["images"][0]["control_image"] = encodeRFC3986PlusURIComponent(playGroundData?.object_name);
    }

    if (playGroundData.modelCode !== 5 && playGroundData?.modelAdapterName?.length) {
      obj["model_adapter"] = playGroundData?.modelAdapterName;
      obj["model_adapter_weight"] = playGroundData?.modelAdapterWeight;
    }
    return obj;
  };

  const generateImage = async () => {
    dispatch(setWorkFlowEngineLoader(true));
    dispatch(setWorkFlowImageData([]));

    let seed = playGroundData?.imageSeed;
    if (seed === null) {
      seed = window.crypto.getRandomValues(new Uint32Array(1))[0];
    }

    let obj;
    if (playGroundDetails?.stabilityValue) {
      obj = {
        model_sub_name: "text-to-image",
        num_images_per_prompt: parseInt(playGroundData?.generatedImageCount),
        images: [{
          output_url: encodeRFC3986PlusURIComponent(playGroundData?.object_name),
          prompt: playGroundData?.promptData[4],
          negative_prompt: playGroundData?.negativePrompt,
          aspect_ratio: Object.keys(stabilityAspectRatios).sort((a, b) => (
            Math.abs(
              stabilityAspectRatios[a].width / stabilityAspectRatios[a].height
              - playGroundData?.imageWidth / playGroundData?.imageHeight
            ) - Math.abs(
              stabilityAspectRatios[b].width / stabilityAspectRatios[b].height
              - playGroundData?.imageWidth / playGroundData?.imageHeight
            )
          ))[0],
        }],
      };
    } else {
      obj = generateCorbuParams();
    }
    obj["seed"] = seed;

    let objBody = {
      email: email,
      project_uuid: playGroundData.project_uuid,
      workflow_uuid: playGroundData?.workflow_uuid,
      object_info: playGroundData?.object_name,
      workflow_json: JSON.stringify(obj),
      use_stability_api: playGroundDetails?.stabilityValue,
    };

    try {
      const res = await ProjectWorkflowGenerate(objBody);
      if (res?.result === true && res?.data?.length) {
        await generateFinalData(res?.data[0]);
        toast(toastFunctionToaster(res.message, "success"));
      } else {
        dispatch(setWorkFlowEngineLoader(false));
        toast(toastFunctionToaster(res.message, "error"));
      }
    } catch (err) {
      dispatch(setWorkFlowEngineLoader(false));
      toast(toastFunctionToaster("Something Went Wrong", "error"));
    }
  };

  const waitForImageOutput = async (requestObj, imgCount) => {
    try {
      let res = await CollectGenerateStatus(requestObj);
      if (!res?.data?.length) {
        throw new Error();
      }
      while (res?.data[0]?.workflow_stage < 2) {
        if (res?.data[0]?.workflow_stage === 1) {
          if (generateTimer.current >= 10 + 2 * imgCount) {
            break;
          }
          generateTimer.current += 1;
        }
        dispatch(setWorkFlowEngineQueue(res?.data[0]?.queue_length));
        res = await CollectGenerateStatus(requestObj);
      }
      generateTimer.current = 0;
      dispatch(setWorkFlowEngineQueue(0));
      return res;
    } catch (err) {
      generateTimer.current = 0;
      dispatch(setWorkFlowEngineQueue(0));
      throw err;
    }
  };

  const generateFinalData = async (data) => {
    dispatch(setWorkflowImgCounter(data?.workflow_counter));
    let objBody = {
      email: email,
      project_uuid: playGroundData.project_uuid,
      workflow_uuid: playGroundData?.workflow_uuid,
      workflow_counter: data?.workflow_counter,
    };
    try {
      const res = await waitForImageOutput(
        objBody,
        playGroundData?.generatedImageCount
      );
      dispatch(setWorkFlowEngineLoader(false));

      if (res?.data?.length) {
        if (res?.data[0]?.workflow_stage === 1) {
          toast(
            toastFunctionToaster(
              "Generation timed out, please check Previous Design tab later for output",
              "error"
            )
          );
        } else if (res?.data[0]?.workflow_stage === 2) {
          dispatch(
            setWorkFlowImageData([{
              images: res?.data[0]?.workflow_output,
              workflowCounter: data?.workflow_counter,
            }])
          );
          toast(
            toastFunctionToaster(
              "Generated outputs collected successfully!",
              "success"
            )
          );
        } else if (res?.data[0]?.workflow_stage === 3) {
          toast(toastFunctionToaster(res?.data[0]?.workflow_output, "error"));
        }
      } else {
        toast(toastFunctionToaster("Something Went Wrong", "error"));
      }
    } catch (err) {
      dispatch(setWorkFlowEngineLoader(false));
      toast(toastFunctionToaster("Something Went Wrong", "error"));
    }
  };

  return (
    <>
      <Flex
        pr="3"
        mt="5px"
        bg={"#151117"}
        borderRadius={"10px"}
        alignItems="center"
        justifyContent="space-between"
        h="5%"
      >
        <Box w="48%">
          <BatchGeneration startGeneration={generateImage} />
        </Box>

        <Button
          size="xs"
          onClick={() => {
            generateImage();
          }}
          colorScheme="green"
          isDisabled={
            !playGroundData?.source_image?.length ||
            !playGroundData?.workflow_uuid
          }
          isLoading={playGroundDetails?.workflowEngineLoader}
        >
          <Text as="span" color="#fff" fontWeight="600">
            Generate
          </Text>
          <Select
            size="xs"
            w="50px"
            m={2}
            color="#fff"
            borderRadius="5px"
            value={playGroundData?.generatedImageCount}
            isDisabled={
              playGroundDetails?.workflowEngineLoader ||
              !playGroundData?.workflow_uuid ||
              !playGroundData?.source_image?.length
            }
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              dispatch({
                type: "SET_WORKFLOW_OBJECT",
                payload: { key: "generatedImageCount", value: parseInt(e.target.value) },
              });
            }}
          >
            {[1, 2, 3, 4].map((num) => (
              <option
                key={num}
                value={num}
                style={{ backgroundColor: "#000", color: "#fff" }}
              >
                {num}
              </option>
            ))}
          </Select>
          <Text as="span" color="#fff" fontWeight="600">
            Image
          </Text>
        </Button>
      </Flex>
    </>
  );
}

export default GenerateAIImage;
