// Chakra Import
import { useDispatch, useSelector } from "react-redux";
import React, { forwardRef, useState, useImperativeHandle, useEffect } from "react";
import { AiFillQuestionCircle } from "react-icons/ai";
import {
  Text,
  Box,
  Flex,
  useToast,
  Image,
  Button,
  VStack,
  HStack,
  Tooltip,
  Radio,
  RadioGroup,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";

// Component Import
import {
  convertCocolibRLEToBasicRLE,
  loadBasicRLEToRawData,
} from "./ImageWithMasksUtils";
import { decodeRFC3986PlusURIComponent } from "../../../utils/encodeImageURI";
import { toastFunctionToaster } from "../../../utils/toastFunction";
import { defaultThemeColor, greyBgColor } from "../../../constants";

// Api Import
import RenderImageUI from "./DesignProjects/PreviousImageConfig/RenderImageUI";
import titleConstants from "../../../constants/TitleConstant";
import tooltipConstants from "../../../constants/TooltipConstant";
import { maskRegionColors } from "../../../constants/Workflow";
import { setTabFlag } from "../../../store/actions/workFlowAction";

const ImageGenerationConfig = forwardRef(({
  rowJsonData,
  workFlowImage,
  useBackground,
  configLoading,
  setConfigLoading,
}, ref) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const jsonData = JSON.parse(rowJsonData);
  const [sourceImageUrl, setSourceImageUrl] = useState(workFlowImage);
  const [controlImageUrl, setControlImageUrl] = useState("");
  const [refImageUrls, setRefImageUrls] = useState(["", "", "", "", ""]);
  const workflowDetails = useSelector((store) => store.workflowDesignHeader);

  useEffect(() => {
    if (
      configLoading &&
      jsonData?.images?.[0]?.regions?.every(
        (data, index) => (!data?.ref_image || refImageUrls[index]?.length > 0)
      ) && (
        !jsonData?.images?.[0]?.control_image || controlImageUrl?.length > 0
      )
    ) {
      setConfigLoading(false);
    }
  }, [configLoading, jsonData, controlImageUrl, refImageUrls])

  // Todo:Render additional workflow data
  const renderProjectWorkflowData = (storeJsonData) => {
    return (
      <Box>
        {storeJsonData?.images?.[0]?.image && (
          <>
            <Flex
              justifyContent={"space-between"}
              p="10px"
              bg="#151117"
              borderTopLeftRadius={"10px"}
              borderTopRightRadius={"10px"}
              borderBottomWidth={"1px"}
              borderBottomColor={"#585858"}
            >
              <Text
                color={defaultThemeColor}
                fontSize={"16px"}
                fontWeight={"600"}
                w="10%"
              >
                Input Images
              </Text>
            </Flex>
            <Flex
              bg="#151117"
              borderBottomLeftRadius={"10px"}
              borderBottomRightRadius={"10px"}
            >
              <Box p="10px" borderRadius={"10px"}>
                <Text
                  color="#fff"
                  fontSize={"14px"}
                  fontWeight={"600"}
                  bg={greyBgColor}
                  borderTopLeftRadius={"10px"}
                  borderTopRightRadius={"10px"}
                  w="100%"
                  p="10px"
                >
                  Source Image
                </Text>
                {workFlowImage ? (
                  <Box h="290px" position="relative">
                    <Image
                      h="100%"
                      w="100%"
                      objectFit={"cover"}
                      src={workFlowImage}
                      borderBottomLeftRadius={"10px"}
                      borderBottomRightRadius={"10px"}
                    />
                  </Box>
                ) : (
                  <RenderImageUI
                    imageName={storeJsonData?.images[0]?.image}
                    imageUrl={sourceImageUrl}
                    setImageUrl={setSourceImageUrl}
                  />
                )}
              </Box>
              {storeJsonData?.images?.[0]?.control_image &&
              storeJsonData?.controlnet_type !== "refine" ? (
                <Box p="10px" borderRadius={"10px"} ml="3">
                  <Flex
                    justifyContent={"space-between"}
                    w="100%"
                    p="10px"
                    bg={greyBgColor}
                    borderTopLeftRadius={"10px"}
                    borderTopRightRadius={"10px"}
                  >
                    <Text color="#fff" fontSize={"14px"} fontWeight={"600"}>
                      Guidance Image
                    </Text>
                    {workFlowImage && (
                      <Button
                        size="xs"
                        isLoading={!controlImageUrl?.length}
                        onClick={() => {
                          cloneGuidanceImage(
                            storeJsonData?.images[0]?.control_image,
                            controlImageUrl,
                            storeJsonData?.images[0]?.control_image_box
                          );
                          dispatch(setTabFlag(0));
                          toast(
                            toastFunctionToaster(
                              "Guidance Image Copied Successfully",
                              "success"
                            )
                          );
                        }}
                        colorScheme="purple"
                      >
                        Clone
                      </Button>
                    )}
                  </Flex>
                  <RenderImageUI
                    imageName={storeJsonData?.images[0]?.control_image}
                    imageUrl={controlImageUrl}
                    setImageUrl={setControlImageUrl}
                  />
                </Box>
              ) : null}
              {storeJsonData.model_sub_name === "inpaint_controlnet" &&
                storeJsonData?.images[0]?.control_image_box && (
                  <Box p="10px" borderRadius={"10px"} ml="3">
                    <Text color={"white"} mr="10px" p="10px">
                      Guidance Overlay Left Boundary:{" "}
                      {storeJsonData?.images[0]?.control_image_box[0]}
                    </Text>
                    <Text color={"white"} mr="10px" p="10px">
                      Guidance Overlay Top Boundary:{" "}
                      {storeJsonData?.images[0]?.control_image_box[1]}
                    </Text>
                    <Text color={"white"} mr="10px" p="10px">
                      Guidance Overlay Right Boundary:{" "}
                      {storeJsonData?.images[0]?.control_image_box[2]}
                    </Text>
                    <Text color={"white"} mr="10px" p="10px">
                      Guidance Overlay Bottom Boundary:{" "}
                      {storeJsonData?.images[0]?.control_image_box[3]}
                    </Text>
                  </Box>
                )}
            </Flex>
          </>
        )}
        {storeJsonData?.images?.[0]?.regions?.length && (
          <Box
            mt="3"
            alignItems="center"
            w="100%"
            bg="#151117"
            borderRadius="10px"
          >
            <Flex
              justifyContent="space-between"
              borderBottomWidth="1px"
              borderBottomColor="#585858"
              p="10px"
              w="100%"
            >
              <Text
                color={defaultThemeColor}
                fontSize="16px"
                fontWeight="600"
                w="20%"
              >
                Prompt and Region Parameters
              </Text>
              {workFlowImage && (
                <Button
                  size="xs"
                  colorScheme="purple"
                  isLoading={storeJsonData?.images?.[0]?.regions?.some(
                    (data, index) => (
                      data?.ref_image?.length > 0 && !refImageUrls[index]?.length
                    )
                  )}
                  onClick={() => {
                    cloneAllRegionData(
                      storeJsonData?.images?.[0]?.regions,
                      refImageUrls,
                      useBackground
                    );
                    dispatch(setTabFlag(0));
                    toast(
                      toastFunctionToaster(
                        "Region Data Copied Successfully", "success"
                      )
                    );
                  }}
                >
                  Copy To Current Design
                </Button>
              )}
            </Flex>
            <Box
              display={"-webkit-box"}
              overflow="auto"
              overflowY="hidden"
              css={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                  height: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "4px",
                  height: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#fff",
                  borderRadius: "10px",
                },
              }}
            >
              {storeJsonData?.images?.[0]?.regions?.map((data, index, arr) => (
                <Box key={index} p="10px" borderRadius={"10px"}>
                  {renderRegionMask(
                    data,
                    refImageUrls[index],
                    index,
                    useBackground && index === arr.length - 1
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        )}
        <Box
          mt="3"
          alignItems={"center"}
          w="100%"
          bg="#151117"
          borderRadius={"10px"}
        >
          <Flex
            justifyContent={"space-between"}
            borderBottomWidth={"1px"}
            borderBottomColor={"#585858"}
            p="10px"
          >
            <Text
              color={defaultThemeColor}
              fontSize={"16px"}
              fontWeight={"600"}
              w="20%"
            >
              Generation Parameters
            </Text>
            {workFlowImage && storeJsonData?.model_sub_name !== "upscale" && (
              <Button
                size="xs"
                colorScheme="purple"
                onClick={() => {
                  storeConfigData(storeJsonData);
                  dispatch(setTabFlag(0));
                  toast(
                    toastFunctionToaster(
                      "Configuration Settings Copied Successfully",
                      "success"
                    )
                  );
                }}
              >
                Copy To Current Design
              </Button>
            )}
          </Flex>
          {renderImageDebugJson(storeJsonData)}
        </Box>
      </Box>
    );
  };

  const renderImageDebugJson = (jsonObj) => {
    if (jsonObj?.model_sub_name !== "upscale") {
      return (
        <>
          <HStack>
            <Box w="50%" alignItems={"center"} key={"left"}>
              {(jsonObj?.model_sub_name === "controlnet" ||
                jsonObj?.model_sub_name === "inpaint_controlnet") &&
                jsonObj?.controlnet_type !== "refine" && (
                  <Box p="2">
                    <Flex justifyContent={"space-between"}>
                      <Text cursor={"pointer"} color="#fff" fontWeight={"600"}>
                        {titleConstants?.guidanceTypeLabel}
                      </Text>
                    </Flex>
                    <RadioGroup
                      value={jsonObj?.controlnet_type}
                      isrequired="true"
                      color="#fff"
                      isDisabled
                    >
                      <Radio value="canny" mr="4" color="#fff">
                        Detailed Model
                      </Radio>
                      <Radio value="lineart" mr="4" color="#fff">
                        Soft Line Art
                      </Radio>
                      <Radio value="sketch" mr="4" color="#fff">
                        Rough Sketch
                      </Radio>
                      <Radio value="depth" color="#fff">
                        Massing Model
                      </Radio>
                    </RadioGroup>
                  </Box>
                )}
              <Box p="2">
                <Flex justifyContent={"space-between"}>
                  <Tooltip
                    placement="left-end"
                    label={tooltipConstants?.speedQualityLabel}
                  >
                    <Text cursor={"pointer"} fontWeight={"600"} color="#fff">
                      {titleConstants?.speedQualityLabel}{" "}
                      {jsonObj?.num_inference_steps}
                    </Text>
                  </Tooltip>
                </Flex>
                <Slider
                  isDisabled={true}
                  min={2}
                  max={12}
                  step={1}
                  defaultValue={4}
                  value={jsonObj?.num_inference_steps}
                >
                  <SliderTrack bg="#fff">
                    <Box position="relative" right={10} />
                    <SliderFilledTrack
                      bg={
                        jsonObj?.num_inference_steps > 8
                          ? "red.500"
                          : defaultThemeColor
                      }
                    />
                  </SliderTrack>
                  <SliderThumb boxSize={3} />
                </Slider>
              </Box>
              <Box p="2">
                <Flex justifyContent={"space-between"}>
                  <Tooltip
                    placement="left-end"
                    label={tooltipConstants?.promptStrengthLabel}
                  >
                    <Text cursor={"pointer"} fontWeight={"600"} color="#fff">
                      {titleConstants?.promptStrengthLabel}{" "}
                      {jsonObj?.guidance_scale}
                    </Text>
                  </Tooltip>
                </Flex>
                <Slider
                  min={1.0}
                  max={2.0}
                  step={0.1}
                  defaultValue={1.5}
                  value={jsonObj?.guidance_scale}
                  isDisabled={true}
                >
                  <SliderTrack bg="#fff">
                    <Box position="relative" right={10} />
                    <SliderFilledTrack bg={defaultThemeColor} />
                  </SliderTrack>
                  <SliderThumb boxSize={3} />
                </Slider>
              </Box>
              {/* Todo:Just Commented Image Sharpness Can be used in future so can uncommend */}
              {/* {jsonObj?.alpha_sharpness ? (
                <Text color={"white"} mr="10px" p="10px">
                  {titleConstants?.imageSharpnessLabel}: {jsonObj?.alpha_sharpness}
                </Text>
              ) : null} */}
              {jsonObj?.model_sub_name === "controlnet" ||
              jsonObj?.model_sub_name === "inpaint_controlnet" ? (
                <Box p="2">
                  <Flex justifyContent={"space-between"}>
                    <Tooltip
                      placement="left-end"
                      label={tooltipConstants?.controlStrengthLabel}
                    >
                      <Text fontWeight={"600"} color="#fff" cursor={"pointer"}>
                        {titleConstants?.controlStrengthLabel}{" "}
                        {jsonObj?.controlnet_conditioning_scale}
                      </Text>
                    </Tooltip>
                  </Flex>
                  <Slider
                    isDisabled={true}
                    min={0.0}
                    max={1.0}
                    step={0.1}
                    defaultValue={0.8}
                    value={jsonObj?.controlnet_conditioning_scale}
                  >
                    <SliderTrack bg="#fff">
                      <Box position="relative" right={10} />
                      <SliderFilledTrack bg={defaultThemeColor} />
                    </SliderTrack>
                    <SliderThumb boxSize={3} />
                  </Slider>
                </Box>
              ) : null}
              {jsonObj?.seed !== null && (
                <Box p="2">
                  <Flex justifyContent={"space-between"}>
                    <Tooltip
                      placement="left-end"
                      label={tooltipConstants?.imageSeedLabel}
                    >
                      <Text fontWeight={"600"} color="#fff" cursor={"pointer"}>
                        {titleConstants?.imageSeedLabel}
                      </Text>
                    </Tooltip>
                    <Tooltip
                      placement="right-end"
                      label="Click to Copy to Current Design"
                    >
                      <Text
                        borderRadius="10px"
                        cursor="pointer"
                        fontSize="12px"
                        color="#fff"
                        p="1"
                        bg={greyBgColor}
                        onClick={() => {
                          if (workFlowImage) {
                            dispatch({
                              type: "SET_WORKFLOW_OBJECT",
                              payload: {
                                key: "imageSeed",
                                value: jsonObj?.seed,
                              },
                            });
                            dispatch(setTabFlag(0));
                          }
                        }}
                      >
                        {jsonObj?.seed}
                      </Text>
                    </Tooltip>
                  </Flex>
                </Box>
              )}
            </Box>
            <Box w="50%" alignItems={"center"} key={"right"}>
              {jsonObj?.model_sub_name === "text_to_image" && (
                <>
                  <Box p="2">
                    <Flex justifyContent={"space-between"}>
                      <Tooltip
                        placement="left-end"
                        label={tooltipConstants?.imageWidthLabel}
                      >
                        <Text
                          fontWeight={"600"}
                          color="#fff"
                          cursor={"pointer"}
                        >
                          Image Width {jsonObj?.width}
                        </Text>
                      </Tooltip>
                    </Flex>
                    <Slider
                      isDisabled={true}
                      min={512}
                      max={1920}
                      step={64}
                      defaultValue={1024}
                      value={jsonObj?.width}
                    >
                      <SliderTrack bg="#fff">
                        <Box position="relative" right={10} />
                        <SliderFilledTrack bg={defaultThemeColor} />
                      </SliderTrack>
                      <SliderThumb boxSize={3} />
                    </Slider>
                  </Box>
                  <Box p="2">
                    <Flex justifyContent={"space-between"}>
                      <Tooltip
                        placement="left-end"
                        label={tooltipConstants?.imageHeightLabel}
                      >
                        <Text
                          fontWeight={"600"}
                          color="#fff"
                          cursor={"pointer"}
                        >
                          Image Height {jsonObj?.height}
                        </Text>
                      </Tooltip>
                    </Flex>
                    <Slider
                      isDisabled={true}
                      min={512}
                      max={1920}
                      step={64}
                      defaultValue={1024}
                      value={jsonObj?.height}
                    >
                      <SliderTrack bg="#fff">
                        <Box position="relative" right={10} />
                        <SliderFilledTrack bg={defaultThemeColor} />
                      </SliderTrack>
                      <SliderThumb boxSize={3} />
                    </Slider>
                  </Box>
                </>
              )}
              {(jsonObj?.model_sub_name === "diffedit" ||
                jsonObj?.model_sub_name === "inpaint_controlnet") && (
                <>
                  <Box p="2">
                    <Flex justifyContent={"space-between"}>
                      <Tooltip
                        placement="left-end"
                        label={tooltipConstants?.inpaintStrengthLabel}
                      >
                        <Text
                          fontWeight={"600"}
                          color="#fff"
                          cursor={"pointer"}
                        >
                          {titleConstants?.inpaintStrengthLabel}{" "}
                          {jsonObj?.inpaint_strength}
                        </Text>
                      </Tooltip>
                    </Flex>
                    <Slider
                      isDisabled={true}
                      min={0.1}
                      max={1.0}
                      step={0.1}
                      defaultValue={1.0}
                      value={jsonObj?.inpaint_strength}
                    >
                      <SliderTrack bg="#fff">
                        <Box position="relative" right={10} />
                        <SliderFilledTrack bg={defaultThemeColor} />
                      </SliderTrack>
                      <SliderThumb boxSize={3} />
                    </Slider>
                  </Box>
                  <Box p="2">
                    <Flex justifyContent={"space-between"}>
                      <Tooltip
                        placement="left-end"
                        label={tooltipConstants?.maskCropPaddingLabel}
                      >
                        <Text
                          fontWeight={"600"}
                          color="#fff"
                          cursor={"pointer"}
                        >
                          {titleConstants?.maskCropPaddingLabel}{" "}
                          {jsonObj?.mask_crop_padding}
                        </Text>
                      </Tooltip>
                    </Flex>
                    <Slider
                      isDisabled={true}
                      min={0.0}
                      max={1.0}
                      step={0.1}
                      defaultValue={0.6}
                      value={jsonObj?.mask_crop_padding}
                    >
                      <SliderTrack bg="#fff">
                        <Box position="relative" right={10} />
                        <SliderFilledTrack bg={defaultThemeColor} />
                      </SliderTrack>
                      <SliderThumb boxSize={3} />
                    </Slider>
                  </Box>
                </>
              )}
              {jsonObj?.images[0]?.regions?.some(
                (data) => data?.ref_image || data?.negative_ref_image
              ) && (
                <>
                  <Box p="2">
                    <Flex justifyContent={"space-between"}>
                      <Tooltip
                        placement="left-end"
                        label={tooltipConstants?.refAdainScaleLabel}
                      >
                        <Text
                          fontWeight={"600"}
                          color="#fff"
                          cursor={"pointer"}
                        >
                          {titleConstants?.refAdainScaleLabel}{" "}
                          {jsonObj?.ref_adain_conditioning_scale}
                        </Text>
                      </Tooltip>
                    </Flex>
                    <Slider
                      isDisabled={true}
                      min={0.0}
                      max={1.0}
                      step={0.1}
                      defaultValue={0.5}
                      value={jsonObj?.ref_adain_conditioning_scale}
                    >
                      <SliderTrack bg="#fff">
                        <Box position="relative" right={10} />
                        <SliderFilledTrack bg={defaultThemeColor} />
                      </SliderTrack>
                      <SliderThumb boxSize={3} />
                    </Slider>
                  </Box>
                  <Box p="2">
                    <Flex justifyContent={"space-between"}>
                      <Tooltip
                        placement="left-end"
                        label={tooltipConstants?.refAttnScaleLabel}
                      >
                        <Text
                          fontWeight={"600"}
                          color="#fff"
                          cursor={"pointer"}
                        >
                          {titleConstants?.refAttnScaleLabel}{" "}
                          {jsonObj?.ref_attn_conditioning_scale}
                        </Text>
                      </Tooltip>
                    </Flex>
                    <Slider
                      isDisabled={true}
                      min={0.0}
                      max={1.0}
                      step={0.1}
                      defaultValue={0.5}
                      value={jsonObj?.ref_attn_conditioning_scale}
                    >
                      <SliderTrack bg="#fff">
                        <Box position="relative" right={10} />
                        <SliderFilledTrack bg={defaultThemeColor} />
                      </SliderTrack>
                      <SliderThumb boxSize={3} />
                    </Slider>
                  </Box>
                </>
              )}
              {jsonObj?.controlnet_type !== "refine" && jsonObj?.model_adapter ? (
                <Box p="2">
                  <Flex justifyContent={"space-between"}>
                    <Tooltip
                      placement="left-end"
                      label={tooltipConstants?.modelAdapterWeightLabel}
                    >
                      <Text fontWeight={"600"} color="#fff" cursor={"pointer"}>
                        {titleConstants?.modelAdapterWeightLabel}{" "}
                        {jsonObj?.model_adapter_weight}
                      </Text>
                    </Tooltip>
                  </Flex>
                  <Slider
                    isDisabled={true}
                    min={0.0}
                    max={1.5}
                    step={0.1}
                    defaultValue={1.0}
                    value={jsonObj?.model_adapter_weight}
                  >
                    <SliderTrack bg="#fff">
                      <Box position="relative" right={10} />
                      <SliderFilledTrack bg={defaultThemeColor} />
                    </SliderTrack>
                    <SliderThumb boxSize={3} />
                  </Slider>
                </Box>
              ) : null}
            </Box>
          </HStack>
          <HStack>
            <Box w="50%" alignItems={"center"} key={"left"}>
              <Flex p="2" justifyContent={"space-between"}>
                <Text
                  cursor="pointer"
                  fontWeight={"500"}
                  textTransform={"capitalize"}
                  color="#fff"
                  mr="2"
                >
                  Negative Prompt
                </Text>
                <Tooltip
                  placement="right-end"
                  label="Click to Copy to Current Design"
                >
                  <Text
                    borderRadius="10px"
                    cursor="pointer"
                    fontSize={"12px"}
                    color="#fff"
                    p="1"
                    bg={greyBgColor}
                    onClick={() => {
                      if (workFlowImage) {
                        dispatch({
                          type: "SET_WORKFLOW_OBJECT",
                          payload: {
                            key: "negativePrompt",
                            value: jsonObj?.images?.[0]?.regions?.[0]?.negative_prompt,
                          },
                        });
                        dispatch(setTabFlag(0));
                      }
                    }}
                  >
                    {jsonObj?.images?.[0]?.regions?.[0]?.negative_prompt || "-"}
                  </Text>
                </Tooltip>
              </Flex>
            </Box>
            {jsonObj?.controlnet_type !== "refine" &&
              <Box w="50%" alignItems={"center"} key={"right"}>
                <Flex p="2" justifyContent={"space-between"}>
                  <Tooltip
                    placement="left-end"
                    label={tooltipConstants?.modelAdapterLabel}
                  >
                    <Text
                      cursor="pointer"
                      fontWeight={"500"}
                      textTransform={"capitalize"}
                      color="#fff"
                      mr="2"
                    >
                      {titleConstants?.modelAdapterLabel}
                    </Text>
                  </Tooltip>
                  <Tooltip
                    placement="right-end"
                    label="Click to Copy to Current Design"
                  >
                    <Text
                      borderRadius="10px"
                      cursor="pointer"
                      fontSize={"12px"}
                      color="#fff"
                      p="1"
                      bg={greyBgColor}
                      autoCapitalize="words"
                      onClick={() => {
                        if (workFlowImage) {
                          dispatch({
                            type: "SET_WORKFLOW_OBJECT",
                            payload: {
                              key: "modelAdapterName",
                              value: jsonObj?.model_adapter,
                            },
                          });
                          dispatch(setTabFlag(0));
                        }
                      }}
                    >
                      {jsonObj?.model_adapter || "None"}
                    </Text>
                  </Tooltip>
                </Flex>
              </Box>
            }
          </HStack>
        </>
      );
    } else {
      return (
        <Box w="100%" alignItems={"center"} key="config">
          <Text color={"white"} mr="10px" p="10px">
            Upscale Factor: {jsonObj?.upscale_factor}
          </Text>
          <Text color={"white"} mr="10px" p="10px" textTransform={"capitalize"}>
            Tiled Upscaling: {jsonObj?.tiled_upscaling ? "Enabled" : "Disabled"}
          </Text>
          <Text color={"white"} mr="10px" p="10px" textTransform={"capitalize"}>
            Images Per Prompt: {jsonObj?.num_images_per_prompt}
          </Text>
        </Box>
      );
    }
  };

  // Todo:Render Multi-region data
  const renderRegionMask = (data, refUrl, index, isBackground) => {
    return (
      <>
        <Box
          w="100%"
          bg={greyBgColor}
          border={"1px"}
          borderColor={
            isBackground ? defaultThemeColor : `${maskRegionColors[index]}.500`
          }
          borderTopLeftRadius={"10px"}
          borderTopRightRadius={"10px"}
        >
          <Flex
            bg={
              isBackground
                ? defaultThemeColor
                : `${maskRegionColors[index]}.500`
            }
            h="8"
            px="2"
            borderRadius={"0px"}
            color="#fff"
            borderTopLeftRadius={"10px"}
            borderTopRightRadius={"10px"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text
              fontSize={"14px"}
              textTransform={"capitalize"}
              fontWeight={"600"}
            >
              {isBackground ? "Background" : `${maskRegionColors[index]}`}
            </Text>
            {workFlowImage && (
              <Button
                size="xs"
                colorScheme="purple"
                isLoading={
                  data?.ref_image?.length > 0 && !refUrl?.length
                }
                onClick={() => {
                  cloneRegionData(data, refUrl, index, isBackground);
                  dispatch(setTabFlag(0));
                  toast(
                    toastFunctionToaster(
                      "Region Data Copied Successfully", "success"
                    )
                  );
                }}
              >
                Copy To Current Design
              </Button>
            )}
          </Flex>
        </Box>
        <Box
          w="100%"
          p="10px"
          border="1px"
          borderColor={
            isBackground ? defaultThemeColor : `${maskRegionColors[index]}.500`
          }
          borderTop={"0px"}
          borderBottomLeftRadius={"10px"}
          borderBottomRightRadius={"10px"}
        >
          {!isBackground && (
            <VStack justifyContent={"space-between"}>
              <Flex>
                <Text fontSize="14px" color="#fff" fontWeight={"600"} mr="2">
                  Region Mask
                </Text>
                <Tooltip label={"Region Mask"}>
                  <Box mt="3px">
                    <AiFillQuestionCircle
                      cursor={"pointer"}
                      color={defaultThemeColor}
                      size="16 "
                    />
                  </Box>
                </Tooltip>
              </Flex>
              <canvas
                height={`${data?.mask?.size[0]}px`}
                width={`${data?.mask?.size[1]}px`}
                ref={(element) => {
                  if (element) {
                    loadBasicRLEToRawData(
                      convertCocolibRLEToBasicRLE(data?.mask?.counts),
                      Math.round(data?.mask?.size[0]),
                      Math.round(data?.mask?.size[1]),
                      element.getContext("2d")
                    );
                  }
                }}
                style={{
                  height: "290px",
                  width: `${Math.round(
                    290 * data?.mask?.size[1] / data?.mask?.size[0]
                  )}px`,
                  margin: "auto",
                }}
              />
            </VStack>
          )}
          {data?.ref_image?.length > 0 && (
            <VStack justifyContent={"space-between"}>
              <Flex>
                <Text fontSize="14px" color="#fff" fontWeight={"600"} mr="2">
                  Reference Image
                </Text>
                <Tooltip label={"Reference Image"}>
                  <Box mt="3px">
                    <AiFillQuestionCircle
                      cursor={"pointer"}
                      color={defaultThemeColor}
                      size="16 "
                    />
                  </Box>
                </Tooltip>
              </Flex>
              <RenderImageUI
                imageName={data?.ref_image}
                imageUrl={refUrl}
                setImageUrl={(url) => {
                  setRefImageUrls((arr) => (
                    [...arr.slice(0, index), url, ...arr.slice(index + 1)]
                  ));
                }}
              />
            </VStack>
          )}
          <VStack justifyContent={"space-between"}>
            <Flex>
              <Text mr="2" fontSize="14px" color="#fff" fontWeight={"600"}>
                Prompt
              </Text>
              <Tooltip label={"Prompt"}>
                <Box mt="3px">
                  <AiFillQuestionCircle
                    cursor={"pointer"}
                    color={defaultThemeColor}
                    size="16 "
                  />
                </Box>
              </Tooltip>
            </Flex>
            <Text
              color="#fff"
              border={"none"}
              borderRadius={"10px"}
              fontSize={"12px"}
            >
              {data?.prompt || "-"}
            </Text>
          </VStack>
        </Box>
      </>
    );
  };

  // Todo:Clone Configurations
  const storeConfigData = (data) => {
    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "guidanceScale", value: data?.guidance_scale },
    });
    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "inferenceSteps", value: data?.num_inference_steps },
    });
    /* Todo:Just Commented Image Sharpness Can be used in future so can uncomment
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: { key: "imageSharpness", value: data?.alpha_sharpness },
      });
    */

    if (
      data?.model_sub_name === "controlnet" ||
      data?.model_sub_name === "inpaint_controlnet"
    ) {
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: { key: "guidanceType", value: data?.controlnet_type },
      });
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "conditionScale",
          value: data?.controlnet_conditioning_scale,
        },
      });
    }

    if (
      data?.model_sub_name === "diffedit" ||
      data?.model_sub_name === "inpaint_controlnet"
    ) {
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: { key: "inpaintStrength", value: data?.inpaint_strength },
      });
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: { key: "maskCropPadding", value: data?.mask_crop_padding },
      });
    }

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: {
        key: "refAdainScale",
        value: data?.ref_adain_conditioning_scale,
      },
    });
    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: {
        key: "refAttnScale",
        value: data?.ref_attn_conditioning_scale,
      },
    });

    if (data?.model_sub_name === "text_to_image") {
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: { key: "imageWidth", value: data?.width },
      });
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: { key: "imageHeight", value: data?.height },
      });
    }

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "imageSeed", value: data?.seed },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "modelAdapterName", value: data?.model_adapter },
    });
    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: {
        key: "modelAdapterWeight",
        value: data?.model_adapter_weight,
      },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: {
        key: "generatedImageCount",
        value: data?.num_images_per_prompt,
      },
    });
  };

  // Todo:Clone Guidance Image
  const cloneGuidanceImage = (imageName, imageUrl, box) => {
    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: {
        key: "control_guidance_image",
        value: imageUrl,
      },
    });
    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: {
        key: "control_guidance_image_name",
        value: decodeRFC3986PlusURIComponent(imageName),
      },
    });
    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: {
        key: "controlBoundingBox",
        value: box && box?.length === 4 ? box : null,
      },
    });
  };

  // Todo:Clone Region Data
  const cloneRegionData = (data, refUrl, index, isBackground) => {
    index = isBackground ? maskRegionColors.length : index;
    const workflowDetail = workflowDetails?.workflowObject;
    const cloneRefImage = [...workflowDetail?.reference_image];
    const cloneRefName = [...workflowDetail?.reference_name];
    const clonePromptData = [...workflowDetail?.promptData];
    const cloneMaskData = [...workflowDetail?.maskData];

    if (data?.ref_image?.length && refUrl?.length) {
      cloneRefImage[index] = refUrl;
      cloneRefName[index] = decodeRFC3986PlusURIComponent(data?.ref_image);
    } else {
      cloneRefImage[index] = "";
      cloneRefName[index] = "";
    }
    clonePromptData[index] = data?.prompt;
    cloneMaskData[index] = data?.mask;

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "maskData", value: cloneMaskData },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "reference_image", value: cloneRefImage },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "reference_name", value: cloneRefName },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "promptData", value: clonePromptData },
    });
  };

  const cloneAllRegionData = (promptData, refUrls, useBackground) => {
    let refImageArr = ["", "", "", "", ""];
    let refNameArr = ["", "", "", "", ""];
    let promptArr = ["", "", "", "", ""];
    let maskData = [null, null, null, null, null];

    promptData.forEach((data, index, arr) => {
      const newIndex =
        useBackground && index === arr.length - 1
          ? maskRegionColors.length
          : index;
      if (data?.ref_image?.length && refUrls[index]?.length) {
        refImageArr[newIndex] = refUrls[index];
        refNameArr[newIndex] = decodeRFC3986PlusURIComponent(data?.ref_image);
      }
      promptArr[newIndex] = data?.prompt;
      maskData[newIndex] = data?.mask;
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "maskData", value: maskData },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "reference_image", value: refImageArr },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "reference_name", value: refNameArr },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "promptData", value: promptArr },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "negativePrompt", value: promptData[0]?.negative_prompt },
    });
  };

  // Todo:Clone All Functionality
  const cloneFunctionality = () => {
    if (
      jsonData.model_sub_name === "inpaint_controlnet" &&
      jsonData?.controlnet_type !== "refine"
    ) {
      cloneGuidanceImage(
        jsonData?.images[0]?.control_image,
        controlImageUrl,
        jsonData?.images[0]?.control_image_box
      );
    }
    storeConfigData(jsonData);
    cloneAllRegionData(
      jsonData?.images?.[0]?.regions, refImageUrls, useBackground
    );
    dispatch(setTabFlag(0));
    toast(toastFunctionToaster("All Settings Copied Successfully", "success"));
  };

  useImperativeHandle(ref, () => cloneFunctionality);

  return renderProjectWorkflowData(jsonData);
});

export default ImageGenerationConfig;
