// Chakra Import
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  Spinner,
  HStack,
  Button,
  useToast,
} from "@chakra-ui/react";
import { defaultThemeColor, greyBgColor } from "../../../../../../constants";

// Api Services Import
import {
  fetchHistoryWorkflowData,
  fetchRootHistory,
} from "../../../../../../services/businessServices";
import EditWorkflow from "../../../../EditWorkflow/EditWorkflow";

// Component Import
import { renderWorkflowTitle } from "../../../../../../constants/Workflow";
import { resetWorkflowData } from "../../../../../../utils/resetWorkflowData";
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";

const HistoryConfig = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [rootWorkflowId, setRootWorkflowId] = useState("");
  const [workflowHistoryData, setworkflowHistoryData] = useState(null);
  const workflowDetails = useSelector((store) => store.workflowDesignHeader);
  const workFlowDetailsData = workflowDetails?.workflowObject;

  const fetchHistoryData = () => {
    setLoading(true);
    const historyObj = {
      project_uuid: workFlowDetailsData?.project_uuid,
      workflow_uuid: rootWorkflowId,
      max_depth: 20,
    };
    fetchHistoryWorkflowData(historyObj)
      .then((res) => {
        if (res?.data[0]?.pagination_data[0]?.records) {
          setworkflowHistoryData(res?.data[0]?.pagination_data[0]?.records);
        } else {
          setworkflowHistoryData([]);
        }
      })
      .catch((err) => {
        toast(toastFunctionToaster(err.message, "error"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchRootWorkflowId = () => {
    setLoading(true);
    const obj = {
      project_uuid: workFlowDetailsData?.project_uuid,
      workflow_uuid: workFlowDetailsData?.workflow_uuid,
    };
    fetchRootHistory(obj)
      .then((res) => {
        if (res?.data[0]?.workflow_uuid) {
          setRootWorkflowId(res?.data[0]?.workflow_uuid);
        } else {
          setRootWorkflowId(workFlowDetailsData?.workflow_uuid);
        }
      })
      .catch((err) => {
        toast(toastFunctionToaster(err.message, "error"));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (
      workFlowDetailsData?.project_uuid &&
      workFlowDetailsData?.workflow_uuid
    ) {
      if (!rootWorkflowId) {
        fetchRootWorkflowId();
      } else {
        fetchHistoryData();
      }
    }
  }, [
    workFlowDetailsData?.project_uuid,
    workFlowDetailsData?.workflow_uuid,
    rootWorkflowId,
  ]);

  // Todo:Render History Component With Nested Child Component
  const renderHistoryData = () => {
    if (workflowHistoryData?.length) {
      return workflowHistoryData.map((workflowData, index) => (
        <WorkflowItem
          key={index}
          workflowData={workflowData}
          greyBgColor={greyBgColor}
          defaultThemeColor={defaultThemeColor}
          loadWorkflow={loadWorkflow}
          renderWorkflowTitle={renderWorkflowTitle}
        />
      ));
    }
    return null;
  };

  // Todo:Workflow HOC (High-Order) Component
  const WorkflowItem = ({
    workflowData,
    greyBgColor,
    defaultThemeColor,
    loadWorkflow,
    renderWorkflowTitle,
  }) => (
    <>
      <HStack
        cursor="pointer"
        mb="10px"
        bg={greyBgColor}
        p="10px"
        borderRadius="5px"
        borderWidth={
          workflowData?.workflow_uuid === workFlowDetailsData?.workflow_uuid
            ? "3px"
            : null
        }
        borderColor={
          workflowData?.workflow_uuid === workFlowDetailsData?.workflow_uuid
            ? "green.500"
            : null
        }
      >
        <Image
          src={workflowData.info?.[0]?.url || "/path/to/default/image.png"}
          alt={workflowData?.name || "Workflow Image"}
          style={{
            height: "100px",
            width: "100px",
            marginRight: "8px",
            objectFit: "cover",
            borderRadius: "5px",
          }}
        />
        <Box w="100%">
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="#fff" fontWeight="600" ml="2">
              {workflowData?.name ? workflowData?.name : "Untitled Workflow"}
            </Text>
            {workflowData?.workflow_uuid !== workFlowDetailsData?.workflow_uuid && (
              <Box
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <EditWorkflow
                  workflowData={workflowData}
                  workflowName={workflowData?.name}
                  project_uuid={workFlowDetailsData?.project_uuid}
                  size="sm"
                  renderList={handlerenderList}
                />
              </Box>
            )}
          </Flex>

          <Text color={defaultThemeColor} fontWeight="600" ml="2">
            {renderWorkflowTitle(workflowData.model_code)}
          </Text>
          <Text color="#fff" ml="2">
            Created:{" "}
            <Text as="span" color={defaultThemeColor}>
              {workflowData?.started}
            </Text>
          </Text>
          <Text color="#fff" ml="2">
            Updated:{" "}
            <Text as="span" color="green.200">
              {workflowData?.updated}
            </Text>
          </Text>
          {workflowData?.workflow_uuid !== workFlowDetailsData?.workflow_uuid && (
            <Button
              size="xs"
              ml="2"
              mt="2"
              colorScheme="green"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                loadWorkflow(e, workflowData);
              }}
            >
              Load Workflow
            </Button>
          )}
        </Box>
      </HStack>
      {workflowData.child_workflows?.length > 0 && (
        <Box mt="4" ml="4">
          {workflowData.child_workflows.map((childWorkflow, index) => (
            <WorkflowItem
              key={index}
              workflowData={childWorkflow}
              greyBgColor={greyBgColor}
              defaultThemeColor={defaultThemeColor}
              loadWorkflow={loadWorkflow}
              renderWorkflowTitle={renderWorkflowTitle}
            />
          ))}
        </Box>
      )}
    </>
  );

  // Todo:Reset Data After Workflow Name Is Updated
  const handlerenderList = (data) => {
    if (data) {
      fetchHistoryData();
    }
  };

  // Todo:Re-direct to Workflow Component
  const loadWorkflow = useCallback(
    (e, data) => {
      e.preventDefault();
      e.stopPropagation();

      resetWorkflowData(dispatch);
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "project_uuid",
          value: workFlowDetailsData?.project_uuid,
        },
      });
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "resource_uuid",
          value: workFlowDetailsData?.resource_uuid,
        },
      });
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "workflow_uuid",
          value: data.workflow_uuid,
        },
      });
      navigate("/business/designProject", {
        state: {
          launcher: "relaunch",
          resource_uuid: workFlowDetailsData?.resource_uuid,
          project_uuid: workFlowDetailsData?.project_uuid,
          workflow_uuid: data.workflow_uuid,
          workflow_model_code: data.model_code,
          object_name: data.info[0].object_name,
          object_url: data.info[0].url,
          workflow_state: "existing",
          workflow_name: data?.name,
        },
      });
    },
    [
      dispatch,
      navigate,
      workFlowDetailsData?.project_uuid,
      workFlowDetailsData?.resource_uuid,
    ]
  );

  return (
    <>
      {loading ? (
        <Flex
          h="60vh"
          w="100%"
          alignItems="center"
          justifyContent="center"
          mt="10"
        >
          <Spinner size="xl" color="#fff" />
        </Flex>
      ) : workflowHistoryData?.length ? (
        renderHistoryData()
      ) : (
        <Text
          fontSize={"16px"}
          fontWeight={"600"}
          color={defaultThemeColor}
          ml="2"
        >
          No Data Found
        </Text>
      )}
    </>
  );
};

export default HistoryConfig;
