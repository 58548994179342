// Chakra Imports
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import LazyLoad from "react-lazyload";
import { AiFillDislike } from "react-icons/ai";
import React, { useEffect, useRef, useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import NoImage from "../../../../../image/no-image.jpg";
import ResponsivePagination from "react-responsive-pagination";
import { AiOutlineStar, AiOutlineCopy } from "react-icons/ai";
import {
  Box,
  Text,
  Slider,
  Button,
  Spinner,
  Image,
  Flex,
  Menu,
  MenuList,
  Tooltip,
  useDisclosure,
  useToast,
  MenuButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SliderMark,
  SliderTrack,
  SliderThumb,
  SliderFilledTrack,
  VStack,
} from "@chakra-ui/react";

// Component Imports
import DownloadImage from "../DownloadImage/DownloadImage";
import ImageGenerationConfig from "../../ImageGenerationConfig";
import { defaultThemeColor, greyBgColor } from "../../../../../constants";
import { toastFunctionToaster } from "../../../../../utils/toastFunction";
import {
  setClipBoardValue,
  setTabFlag,
} from "../../../../../store/actions/workFlowAction";
import { useNavigate } from "react-router-dom";
import { resetWorkflowData } from "../../../../../utils/resetWorkflowData";

// Service Imports
import {
  collectLikedImages,
  unLikeSelectedImage,
} from "../../../../../services/projectTemplateService";
import CopyImageToClipboard from "../../../../Custom/CopyImageToClipboard";
import SharedSpaceWorkflow from "../PreviousImageConfig/SharedSpaceWorkflow";
import AddNewWorkflow from "../AddNewWorkflow/AddNewWorkflow";

function LikedImage({ projectId, resourceId, workflowId, tabFlag, type }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, isLoading] = useState(false);
  const [style, setStyle] = useState(0);
  const [quality, setQuality] = useState(0);
  const [lightning, setLightning] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [likeRating, setLikeRating] = useState([]);
  const [likedImageData, setLikedImageData] = useState([]);
  const { copyImageToClipboard } = CopyImageToClipboard(); //Custom Clipboard Hook
  const workflowDetails = useSelector((store) => store.workflowDesignHeader);
  const workflowDetailsData = workflowDetails?.workflowObject;

  useEffect(() => {
    if (projectId && tabFlag === 2) {
      fetchLikedImageData();
    }
  }, [tabFlag, currentPage, projectId]);

  //   Todo:To fetched liked images
  const fetchLikedImageData = () => {
    isLoading(true);
    let obj = {
      records_per_page: 0,
      page_id: currentPage,
      project_uuid: projectId,
      min_lightening_count: lightning,
      min_style_count: style,
      min_quality_count: quality,
    };
    if (workflowId) {
      obj["workflow_uuid"] = workflowId;
    }
    collectLikedImages(obj)
      .then((res) => {
        if (res?.result) {
          if (res?.data[0]) {
            setLikeRating(
              res?.data[0]?.pagination_data[0]?.records[0]?.star_data
            );
            setLikedImageData(
              res?.data[0]?.pagination_data[0]?.records[0]?.images
            );
            setTotalPages(res?.data[0]?.pagination_data[0]?.total_pages);
            if (res?.data[0]?.pagination_data[0]?.total_pages < currentPage) {
              setCurrentPage(
                Math.max(1, res?.data[0]?.pagination_data[0]?.total_pages)
              );
            }
          } else {
            setLikeRating([]);
            setLikedImageData([]);
          }
        } else {
          setLikeRating([]);
          setLikedImageData([]);
          toast(toastFunctionToaster(res.message, "error"));
        }
      })
      .catch((err) => {
        setLikeRating([]);
        setLikedImageData([]);
        toast(toastFunctionToaster(err.message, "error"));
      })
      .finally(() => {
        isLoading(false);
      });
  };

  //   Todo:Slider component function
  const renderFilterData = () => {
    return (
      <Flex
        justifyContent={"space-between"}
        alignItems={"center"}
        borderBottom={"1px"}
        borderColor={"#252628"}
      >
        <Flex w="10%" textAlign={"center"} borderRight={"1px"} p="3">
          <Text color="#fff" fontSize={"16px"}>
            Filter By Rating
          </Text>
        </Flex>
        <Flex
          w="80%"
          justifyContent={"space-between"}
          borderRight={"1px"}
          p="3"
        >
          <Flex mb="5" w="30%">
            <Text color="#fff" fontSize="14px" fontWeight="600" mr="4">
              Lighting
            </Text>
            <Slider
              aria-label="slider-ex-1"
              min={0}
              max={5}
              colorScheme="yellow"
              defaultValue={lightning}
              onChange={(e) => {
                setLightning(e);
              }}
            >
              <SliderMark value={0} mt="2" fontSize="sm" color="#fff">
                0
              </SliderMark>
              <SliderMark value={1} mt="2" fontSize="sm" color="#fff">
                1
              </SliderMark>
              <SliderMark value={2} mt="2" fontSize="sm" color="#fff">
                2
              </SliderMark>
              <SliderMark value={3} mt="2" fontSize="sm" color="#fff">
                3
              </SliderMark>
              <SliderMark value={4} mt="2" fontSize="sm" color="#fff">
                4
              </SliderMark>
              <SliderMark value={5} mt="2" fontSize="sm" color="#fff">
                5
              </SliderMark>
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </Flex>
          <Flex mb="5" w="30%">
            <Text color="#fff" fontSize="14px" fontWeight="600" mr="4">
              Style
            </Text>
            <Slider
              aria-label="slider-ex-1"
              min={0}
              max={5}
              colorScheme="yellow"
              defaultValue={style}
              onChange={(e) => {
                setStyle(e);
              }}
            >
              <SliderMark value={0} mt="2" fontSize="sm" color="#fff">
                0
              </SliderMark>
              <SliderMark value={1} mt="2" fontSize="sm" color="#fff">
                1
              </SliderMark>
              <SliderMark value={2} mt="2" fontSize="sm" color="#fff">
                2
              </SliderMark>
              <SliderMark value={3} mt="2" fontSize="sm" color="#fff">
                3
              </SliderMark>
              <SliderMark value={4} mt="2" fontSize="sm" color="#fff">
                4
              </SliderMark>
              <SliderMark value={5} mt="2" fontSize="sm" color="#fff">
                5
              </SliderMark>
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </Flex>
          <Flex mb="5" w="30%">
            <Text color="#fff" fontSize="14px" fontWeight="600" mr="4">
              Quality
            </Text>
            <Slider
              aria-label="slider-ex-1"
              min={0}
              max={5}
              colorScheme="yellow"
              defaultValue={quality}
              onChange={(e) => {
                setQuality(e);
              }}
            >
              <SliderMark value={0} mt="2" fontSize="sm" color="#fff">
                0
              </SliderMark>
              <SliderMark value={1} mt="2" fontSize="sm" color="#fff">
                1
              </SliderMark>
              <SliderMark value={2} mt="2" fontSize="sm" color="#fff">
                2
              </SliderMark>
              <SliderMark value={3} mt="2" fontSize="sm" color="#fff">
                3
              </SliderMark>
              <SliderMark value={4} mt="2" fontSize="sm" color="#fff">
                4
              </SliderMark>
              <SliderMark value={5} mt="2" fontSize="sm" color="#fff">
                5
              </SliderMark>
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </Flex>
        </Flex>
        <Flex w="10%" justifyContent={"center"} textAlign={"center"}>
          <Button
            size="sm"
            bg={defaultThemeColor}
            onClick={() => {
              fetchLikedImageData();
            }}
            isDisabled={loading ? true : false}
            isLoading={loading ? true : false}
          >
            Apply
          </Button>
        </Flex>
      </Flex>
    );
  };

  const copyToClipboard = (data) => {
    if (data?.object_name?.length) {
      dispatch(setClipBoardValue(data?.object_name));
      copyImageToData(data?.object_name);
    }
  };

  // Todo:Custom Copy To Clipboard Hook
  const copyImageToData = async (imageName) => {
    try {
      await copyImageToClipboard(imageName);
      toast(toastFunctionToaster("Image copied to clipboard", "success"));
    } catch (error) {
      toast(toastFunctionToaster("Failed to copy image to clipboard", "error"));
    }
  };

  const routetoWorkflow = (ratingDetails) => {
    if (type === "playground") {
      navigate("/business/playgroundDesign", {
        state: {
          launcher: "relaunch",
          project_uuid: "",
          workflow_uuid: ratingDetails.workflow_uuid,
          workflow_model_code: ratingDetails.workflow_model_code,
          object_name: ratingDetails?.workflow_info[0].object_name,
          object_url: ratingDetails?.workflow_info[0]?.url,
          workflow_state: "existing",
          workflow_name: ratingDetails?.workflow_name,
        },
      });
    } else {
      navigate("/business/designProject", {
        state: {
          launcher: "relaunch",
          resource_uuid: resourceId,
          project_uuid: projectId,
          workflow_uuid: ratingDetails.workflow_uuid,
          workflow_model_code: ratingDetails.workflow_model_code,
          object_name: ratingDetails?.workflow_info[0]?.object_name,
          object_url: ratingDetails?.workflow_info[0]?.url,
          workflow_state: "existing",
          workflow_name: ratingDetails?.workflow_name,
        },
      });
    }
  };

  const imageConfigComponent = (
    data,
    imageWorkflowId,
    imageWorkflowName,
    imageStarId,
    workflowCounter,
    imageIndexValue,
  ) => {
    return (
      <Menu as={Box} position="relative" zIndex={10}>
        <MenuButton
          color="#fff"
          position={"absolute"}
          right="1"
          top="1"
          bg={greyBgColor}
          fontSize={"20px"}
          borderRadius={"10px"}
          zIndex={1}
        >
          <BiDotsVerticalRounded />
        </MenuButton>
        <MenuList
          bg={greyBgColor}
          border="none"
          minW="100px !important"
          position="absolute"
          left="-90px"
        >
          <Flex justifyContent={"space-between"} px="2">
            <Tooltip label="Download Image" placement="top">
              <Box>
                <DownloadImage
                  position={"relative"}
                  right={"0"}
                  imageUrl={data.url?.length ? data.url : NoImage}
                ></DownloadImage>
              </Box>
            </Tooltip>
            <Tooltip label="Copy to Clipboard" placement="top">
              <Box>
                <AiOutlineCopy
                  size="20px"
                  cursor="pointer"
                  color={defaultThemeColor}
                  onClick={() => {
                    copyToClipboard(data);
                  }}
                />
              </Box>
            </Tooltip>
            <AddNewWorkflow
              type={type}
              imageWorkflowId={imageWorkflowId}
              imageWorkflowName={imageWorkflowName}
              projectId={projectId}
              resourceId={resourceId}
              imageIndexValue={imageIndexValue}
              workflowCounter={workflowCounter}
              objData={data}
              resetData={() => {
                resetWorkflowData(dispatch);
                dispatch(setTabFlag(0));
              }}
              fontSize="20px"
              menuLeft="90px"
            />
            <Tooltip label="Unlike Image" placement="top">
              <Box>
                <AiFillDislike
                  size="20px"
                  cursor="pointer"
                  color={defaultThemeColor}
                  onClick={(e) => {
                    removeLikedImage(e, imageStarId);
                  }}
                />
              </Box>
            </Tooltip>
            <SharedSpaceWorkflow
              projectId={projectId}
              objectKey={data?.object_name}
              fontSize="20px"
            />
          </Flex>
        </MenuList>
      </Menu>
    );
  };

  // Todo:Remove Liked Images
  const removeLikedImage = (e, imageStarId) => {
    e.preventDefault();
    isLoading(true);
    let obj = { image_star_uuid: imageStarId };
    unLikeSelectedImage(obj)
      .then((res) => {
        if (res?.result) {
          fetchLikedImageData();
          toast(toastFunctionToaster(res?.message, "success"));
        } else {
          toast(toastFunctionToaster(res?.message, "error"));
          isLoading(false);
        }
      })
      .catch((err) => {
        toast(toastFunctionToaster("Something Went Wrong", "error"));
        isLoading(false);
      });
  };

  // Todo:Merge Json and Image into an array and render the image
  const LikedImageContainer = ({ likedImageData }) => {
    const popupSliderRef = useRef(null);
    const configSliderRef = useRef(null);
    const [modalWidth, setModalWidth] = useState(0);
    const [indexValue, setIndexValue] = useState(0);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
      isOpen: configIsOpen,
      onOpen: onConfigOpen,
      onClose: configOnClose,
    } = useDisclosure();

    const handleKeyDown = (e) => {
      if (popupSliderRef.current) {
        if (e.key === "ArrowLeft") {
          popupSliderRef.current.slickPrev();
        } else if (e.key === "ArrowRight") {
          popupSliderRef.current.slickNext();
        }
      } else if (configSliderRef.current) {
        if (e.key === "ArrowLeft") {
          configSliderRef.current.slickPrev();
        } else if (e.key === "ArrowRight") {
          configSliderRef.current.slickNext();
        }
      };
    };

    const sliderSettings = {
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: likedImageData?.length, // Disable arrows until all images are loaded
      dots: likedImageData?.length, // Disable dots until all images are loaded
      draggable: likedImageData?.length, // Disable dragging until all images are loaded
      swipe: likedImageData?.length, // Disable swipping until all images are loaded
      initialSlide: indexValue,
    };

    useEffect(() => {
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, []);

    const handleImageLoad = (e) => {
      setModalWidth(e.target.naturalWidth);
    };

    const imageJsonData = likedImageData?.map((subData) => (subData[0]));
    const configJsonData = likedImageData?.map((subData) => (subData[1].json));

    return (
      <Box
        h="calc(100vh - 240px)"
        p="3"
        display={"-webkit-box"}
        overflow="auto"
        overflowX="hidden"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#fff",
            borderRadius: "10px",
          },
        }}
      >
        <Flex flexWrap={"wrap"} w="100%">
          {imageJsonData?.map((subData, index) => (
            <SingleLikedImageDisplay
              key={index}
              imageData={subData}
              imageRating={likeRating[index]}
              onOpen={() => {
                setIndexValue(index);
                onOpen();
              }}
              configOnOpen={() => {
                setIndexValue(index);
                onConfigOpen();
              }}
            />
          ))}
        </Flex>
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          scrollBehavior="outside"
          style={{ width: "100% !important" }}
        >
          <ModalOverlay />
          <ModalContent maxW={"80%"} width={modalWidth} mt="5" bg="#0b090c">
            <ModalCloseButton
              color="red.500"
              fontWeight={"600"}
              bg="#fff"
              top="0"
              right="0"
              zIndex="999"
            />
              <SlickSlider ref={popupSliderRef} {...sliderSettings}>
                {imageJsonData?.map((subData, index) => (
                  <LazyLoad key={index}>
                    <Image
                      src={subData?.url}
                      alt="Image"
                      objectFit="contain"
                      height="95vh"
                      bg="#0b090c"
                      width="100%"
                      onLoad={handleImageLoad}
                    />
                  </LazyLoad>
                ))}
              </SlickSlider>
          </ModalContent>
        </Modal>
        <Modal
          isOpen={configIsOpen}
          onClose={configOnClose}
          scrollBehavior="outside"
          style={{ width: "100% !important" }}
          size={"6xl"}
        >
          <ModalOverlay />
          <ModalContent mt="5" bg="#0b090c">
            <ModalCloseButton
              color="red.500"
              fontWeight={"600"}
              bg="#fff"
              top="0"
              right="0"
              zIndex="999"
            />
              <SlickSlider ref={configSliderRef} {...sliderSettings}>
                {configJsonData?.map((subData, index) => (
                  <Box
                    height="90vh"
                    width="90vw"
                    p="2"
                  >
                    <ImageGenerationConfig
                      key={index}
                      rowJsonData={subData}
                      workFlowImage={workflowId
                        ? workflowDetailsData?.source_image
                        : null
                      }
                      useBackground={
                        workflowId
                        ? (workflowDetailsData?.modelCode === 1 ||
                          workflowDetailsData?.modelCode === 3 ||
                          workflowDetailsData?.modelCode === 4
                        )
                        : null
                      }
                    />
                  </Box>
                ))}
              </SlickSlider>
          </ModalContent>
        </Modal>
      </Box>
    );
  };

  // Todo:Render Liking Component
  const StarComponent = ({ count, setCount, defaultThemeColor }) => (
    <>
      {[...Array(5)].map((_, index) => (
        <AiOutlineStar
          key={index}
          color={count >= index + 1 ? defaultThemeColor : "#fff"}
          cursor="pointer"
          fontSize="20px"
        />
      ))}
    </>
  );

  function SingleLikedImageDisplay({ imageData, imageRating, onOpen, configOnOpen }) {
    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);

    return (
      <>
        <Box height={"290px"} m="1%" position={"relative"}>
          {imageWidth ? (
            imageConfigComponent(
              imageData,
              imageRating?.workflow_uuid,
              imageRating?.workflow_name,
              imageRating?.image_star_uuid,
              imageRating?.workflow_counter,
              imageRating?.image_id,
            )
          ) : null}
          <Image
            src={imageData?.url}
            w={"100%"}
            h="100%"
            objectFit={"contain"}
            cursor="pointer"
            onLoad={(e) => {
              setImageWidth(e.target.naturalWidth);
              setImageHeight(e.target.naturalHeight);
            }}
            onClick={() => {
              onOpen();
            }}
          />
          {imageWidth ? (
            <Box position="absolute" bottom="0" bg={greyBgColor} p="2" w="100%">
              <Flex
                w="100%"
                flexWrap={"wrap"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box>
                  <Flex key="lighting">
                    <Text color="#fff" mr="2">
                      Lighting
                    </Text>
                    <Flex>
                      <StarComponent
                        count={imageRating?.lightening_count}
                        defaultThemeColor={defaultThemeColor}
                      />
                    </Flex>
                  </Flex>
                  <Flex key="style">
                    <Text color="#fff" mr="7">
                      Style
                    </Text>
                    <Flex>
                      <StarComponent
                        count={imageRating?.style_count}
                        defaultThemeColor={defaultThemeColor}
                      />
                    </Flex>
                  </Flex>
                  <Flex key="quality">
                    <Text color="#fff" mr="14px">
                      Quality
                    </Text>
                    <Flex>
                      <StarComponent
                        count={imageRating?.quality_count}
                        defaultThemeColor={defaultThemeColor}
                      />
                    </Flex>
                  </Flex>
                </Box>
                <VStack>
                  {!workflowId && (
                    <Button
                      size="xs"
                      colorScheme="green"
                      onClick={() => {
                        routetoWorkflow(imageRating);
                      }}
                    >
                      Open Workflow
                    </Button>
                  )}
                  <Button
                    size="xs"
                    mt="2"
                    colorScheme="yellow"
                    onClick={() => {
                      configOnOpen();
                    }}
                  >
                    Open Config
                  </Button>
                </VStack>
              </Flex>
            </Box>
          ) : null}
          {imageWidth ? (
            <Box
              color="#fff"
              position="absolute"
              top="0"
              bg={greyBgColor}
              fontWeight={"600"}
              p="2"
            >
              {imageWidth} x {imageHeight}
            </Box>
          ) : null}
        </Box>
      </>
    );
  }

  return (
    <Box borderRadius="10px" w="100%" mt="3" bg={"#151117"} h="100%">
      {renderFilterData()}
      {loading ? (
        <Flex alignItems="center" justifyContent="center" mt="10" h="60vh">
          <Box textAlign={"center"}>
            <Spinner size="xl" color="#fff" />
          </Box>
        </Flex>
      ) : (
        <>
          {likedImageData?.length ? (
            <>
              <LikedImageContainer
                likedImageData={likedImageData}
              />
              <Box mt="4">
                <ResponsivePagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                />
              </Box>
            </>
          ) : (
            <Text
              fontWeight="600"
              fontSize="16px"
              p="2"
              m="2"
              color={defaultThemeColor}
            >
              No Images Found....
            </Text>
          )}
        </>
      )}
    </Box>
  );
}

export default LikedImage;
