export const encodeRFC3986PlusURIComponent = (str) => {
  const [businessKey, resourceKey, ...imagePathEls] = str.split("/");
  const encodedImagePath = encodeURIComponent(imagePathEls.join("/")).replace(
    /[!'()*]/g,
    (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`,
  ).replace(/%20/g, '+');
  return `https://corbupvt.s3.us-west-2.amazonaws.com/${businessKey}/${resourceKey}/${encodedImagePath}`;
}

export const decodeRFC3986PlusURIComponent = (str) => {
  const imageName = str.slice(str.indexOf(".com") + 5);
  const [businessKey, resourceKey, encodedURI] = imageName.split("/");
  const decodedImagePath = decodeURIComponent(encodedURI.replace(/\+/g, "%20"));
  return `${businessKey}/${resourceKey}/${decodedImagePath}`;
}
