export const maskRegionColors = ["red", "blue", "green", "yellow"];
export const stabilityAspectRatios = {
  "21:9": {
    title: "Ultrawide",
    height: 640,
    width: 1536,
  },
  "16:9": {
    title: "Wide",
    height: 768,
    width: 1344,
  },
  "3:2": {
    title: "Landscape",
    height: 832,
    width: 1216,
  },
  "1:1": {
    title: "Square",
    height: 1024,
    width: 1024,
  },
  "2:3": {
    title: "Portrait",
    height: 1216,
    width: 832,
  },
  "9:16": {
    title: "Narrow",
    height: 1344,
    width: 768,
  },
  // "9:21": {
  //   title: "Ultranarrow",
  //   height: 1536,
  //   width: 640,
  // },
};

export const wizardTitles = [
  {
    title: "Create",
    modelCode: 1,
    url: "https://corbuimgs.s3.us-west-2.amazonaws.com/images/text-2-image.png",
    subTitle: "Create",
    placeholderTitle: "Create an image from one or more text descriptions.",
  },
  {
    title: "Edit",
    modelCode: 2,
    url: "",
    subTitle: "Edit",
    placeholderTitle: "Upload an image and replace or modify selected regions.",
  },
  {
    title: "Render",
    modelCode: 4,
    url: "",
    subTitle: "Render",
    placeholderTitle:
      "Upload a sketch, photo, or view from a 3D Model and generate concept renders.",
  },
  {
    title: "Remix",
    modelCode: 3,
    url: "",
    subTitle: "Remix",
    placeholderTitle: "Upload an image and generate restyled or remixed versions.",
  },
  {
    title: "Enhance",
    modelCode: 5,
    url: "",
    subTitle: "Enhance",
    placeholderTitle:
      "Creative Upscaler for parts of the image or entire scene.",
  },
];

// Helper function to get workflow title
export const renderWorkflowTitle = (model_code) => {
  for (const title of wizardTitles) {
    if (title.modelCode === parseInt(model_code)) {
      return title.subTitle;
    }
  }
  // Handle other cases or provide a default title
  return "Workflow";
};
