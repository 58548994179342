// React and Chakra Imports
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useRef } from "react";
import { Button, Flex, Tooltip, useToast } from "@chakra-ui/react";
import { defaultThemeColor } from "../../../../../../constants";
import { getEmailId } from "../../../../../../utils/localStorageIndex";
import {
  CollectGenerateStatus,
  ProjectWorkflowGenerate,
} from "../../../../../../services/projectTemplateService";
import { encodeRFC3986PlusURIComponent } from "../../../../../../utils/encodeImageURI";
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";
import {
  setWorkFlowEngineLoader,
  setWorkFlowEngineQueue,
  setWorkFlowImageData,
  setWorkflowImgCounter,
} from "../../../../../../store/actions/workFlowAction";

function ImageXConfig({ height, imgUrl, width, marginTop }) {
  const toast = useToast();
  const email = getEmailId();
  const dispatch = useDispatch();
  const generateTimer = useRef(0);
  const [outputselectedValue, setOutputValue] = useState(0);

  // Todo:Fetch details from redux store
  const workflowDetails = useSelector((store) => store.workflowDesignHeader);
  const workflowDetailsData = workflowDetails?.workflowObject;

  const waitForImageOutput = async (requestObj) => {
    try {
      let res = await CollectGenerateStatus(requestObj);
      if (!res?.data?.length) {
        throw new Error();
      }
      while (res?.data[0]?.workflow_stage < 2) {
        if (res?.data[0]?.workflow_stage === 1) {
          if (generateTimer.current >= 9) {
            break;
          }
          generateTimer.current += 1;
        }
        dispatch(setWorkFlowEngineQueue(res?.data[0]?.queue_length));
        res = await CollectGenerateStatus(requestObj);
      }
      generateTimer.current = 0;
      dispatch(setWorkFlowEngineQueue(0));
      return res;
    } catch (err) {
      generateTimer.current = 0;
      dispatch(setWorkFlowEngineQueue(0));
      throw err;
    }
  }

  const regenrateUpgaredImage = (value) => {
    const maxResultDimension = Math.max(width, height) * value;
    if (maxResultDimension <= 4096) {
      const tempImageCount = workflowDetailsData?.generatedImageCount;
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: { key: "generatedImageCount", value: 1 },
      });
      dispatch(setWorkFlowEngineLoader(true));
      dispatch(setWorkFlowImageData([]));

      let obj = {
        model_sub_name: "upscale",
        upscale_factor: value,
        num_images_per_prompt: 1,
        image: [encodeRFC3986PlusURIComponent(imgUrl?.object_name)],
      };

      let objBody = {
        email: email,
        project_uuid: workflowDetailsData?.project_uuid,
        workflow_uuid: workflowDetailsData?.workflow_uuid,
        object_info: workflowDetailsData?.object_name,
        workflow_json: JSON.stringify(obj),
      };

      ProjectWorkflowGenerate(objBody)
        .then((res) => {
          if (res?.result === true && res?.data?.length) {
            generateFinalData(res?.data[0], tempImageCount);
            toast(toastFunctionToaster(res.message, "success"));
          } else {
            dispatch(setWorkFlowEngineLoader(false));
            toast(toastFunctionToaster(res.message, "error"));
          }
        })
        .catch((err) => {
          toast(toastFunctionToaster("Something Went Wrong", "error"));
          dispatch(setWorkFlowEngineLoader(false));
          dispatch({
            type: "SET_WORKFLOW_OBJECT",
            payload: { key: "generatedImageCount", value: tempImageCount },
          });
        })
    } else {
      toast(
        toastFunctionToaster("Image is Too Large to Upscale", "error")
      );
      setOutputValue(0);
    }
  };

  // Todo:Generate Final Data
  const generateFinalData = async (data, tempImageCount) => {
    dispatch(setWorkflowImgCounter(data?.workflow_counter));
    let objBody = {
      email: email,
      project_uuid: workflowDetailsData?.project_uuid,
      workflow_uuid: workflowDetailsData?.workflow_uuid,
      workflow_counter: data?.workflow_counter,
    };
    try {
      const res = await waitForImageOutput(objBody);
      dispatch(setWorkFlowEngineLoader(false));

      if (res?.data?.length) {
        if (res?.data[0]?.workflow_stage === 1) {
          toast(toastFunctionToaster(
            "Generation timed out, please check Previous Design tab later for output", "error"
          ));
        } else if (res?.data[0]?.workflow_stage === 2) {
          dispatch(
            setWorkFlowImageData([{
              images: res?.data[0]?.workflow_output,
              workflowCounter: data?.workflow_counter,
            }])
          );
          toast(toastFunctionToaster("Generated outputs collected successfully!", "success"));
        } else if (res?.data[0]?.workflow_stage === 3) {
          toast(toastFunctionToaster(res?.data[0]?.workflow_output, "error"));
        }
      } else {
        toast(toastFunctionToaster("Something Went Wrong", "error"));
      }
    } catch (err) {
      dispatch(setWorkFlowEngineLoader(false));
      toast(toastFunctionToaster("Something Went Wrong", "error"));
    } finally {
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: { key: "generatedImageCount", value: tempImageCount },
      });
    }
  };

  return (
    <Flex justifyContent={"space-between"} px="2" mt={marginTop ? marginTop : "2"}>
      <Tooltip label="2x Upscale">
        <Button
          size="xs"
          mr="1"
          bg={outputselectedValue === 2 ? "green.400" : defaultThemeColor}
          color="#000"
          onClick={() => {
            setOutputValue(2);
            regenrateUpgaredImage(2);
          }}
          _hover={{ boxShadow: "none" }}
          _active={{ bg: "none" }}
          isDisabled={workflowDetails?.workflowEngineLoader}
          isLoading={workflowDetails?.workflowEngineLoader}
        >
          2X
        </Button>
      </Tooltip>
      <Tooltip label="3x Upscale">
        <Button
          size="xs"
          mr="1"
          bg={outputselectedValue === 3 ? "green.400" : defaultThemeColor}
          color="#000"
          onClick={() => {
            setOutputValue(3);
            regenrateUpgaredImage(3);
          }}
          _hover={{ boxShadow: "none" }}
          _active={{ bg: "none" }}
          isDisabled={workflowDetails?.workflowEngineLoader}
          isLoading={workflowDetails?.workflowEngineLoader}
        >
          3X
        </Button>
      </Tooltip>
      <Tooltip label="4x Upscale">
        <Button
          size="xs"
          bg={outputselectedValue === 4 ? "green.400" : defaultThemeColor}
          color="#000"
          onClick={() => {
            setOutputValue(4);
            regenrateUpgaredImage(4);
          }}
          _hover={{ boxShadow: "none" }}
          _active={{ bg: "none" }}
          isDisabled={workflowDetails?.workflowEngineLoader}
          isLoading={workflowDetails?.workflowEngineLoader}
        >
          4X
        </Button>
      </Tooltip>
    </Flex>
  );
}

export default ImageXConfig;
