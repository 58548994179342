import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
  useToast,
} from "@chakra-ui/react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import imageCompression from "browser-image-compression";
import { defaultGreyColor, greyBgColor } from "../../../constants";
import { toastFunctionToaster } from "../../../utils/toastFunction";

function ImageCompressorModal({
  handleSubmitNew,
  imageHeight,
  imageWidth,
  isModalOpen,
  onModalClose,
  uploadedImage,
  uploadLoading,
  cropImageFlag,
}) {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [cropper, setCropper] = useState(null);
  const [imageSrc, setImageSrc] = useState("");

  const compressImageSize = async () => {
    if (uploadedImage) {
      const options = {
        maxWidthOrHeight: 2048,
        useWebWorker: true,
        onProgress: setProgressPercentage,
      };
      setLoading(true);
      try {
        const compressedFile = await imageCompression(uploadedImage, options);
        await handleSubmitNew(compressedFile);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        setProgressPercentage(0);
        onModalClose();
      }
    }
  };

  const base64ToFile = (base64Data, fileName, fileType) => {
    return fetch(base64Data)
      .then((res) => res.blob())
      .then((blob) => new File([blob], fileName, { type: fileType }));
  };

  const handleCropImageClick = async () => {
    if (cropper) {
      try {
        const croppedCanvas = cropper.getCroppedCanvas(
          { maxWidth: 3072, maxHeight: 3072 }
        );
        const { x, y, width, height } = cropper.getData(true);
        const imageEls = uploadedImage.name.split(".")
        const croppedImageDataURL = croppedCanvas.toDataURL("image/jpeg");
        const fileName = `${
          imageEls.slice(0, imageEls.length - 1).join(".")
        }_${x}_${y}_${width}_${height}.jpg`;
        const fileType = uploadedImage.type;
        const croppedFile = await base64ToFile(
          croppedImageDataURL,
          fileName,
          fileType
        );

        await handleSubmitNew(croppedFile);
      } catch (error) {
        toast(toastFunctionToaster("Error cropping image...", "error"));
        console.error("Error cropping image:", error);
      }
    }
  };

  const handleImageUpload = useCallback(() => {
    if (uploadedImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(uploadedImage);
    }
  }, [uploadedImage]);

  useEffect(() => {
    if (uploadedImage) {
      handleImageUpload();
    }
  }, [uploadedImage, handleImageUpload]);

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isModalOpen}
      onClose={onModalClose}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent bg={greyBgColor}>
        <ModalHeader
          mb="3"
          color="#fff"
          borderBottomWidth={"1px"}
          borderBottomColor={defaultGreyColor}
        >
          {cropImageFlag
            ? "Please set the bounding box for the cropped image"
            : "Image is too large, do you want to resize it before uploading?"
          }
        </ModalHeader>
        <ModalBody borderColor={defaultGreyColor}>
          {loading ? (
            <>
              <Flex justifyContent={"space-between"}>
                <Text color="#fff">Resizing Image...</Text>
                <Text color="green.500">{progressPercentage}% Complete</Text>
              </Flex>
              <Progress
                value={progressPercentage}
                colorScheme="green"
                size="md"
              />
            </>
          ) : (
            <Flex alignItems="center" justifyContent={"space-between"}>
              <Text color="#fff" my="3">
                Current Image Size: {imageWidth} x {imageHeight} px
              </Text>
            </Flex>
          )}
          {cropImageFlag && (
            <Box position="relative" mb="3" background="#494552">
              <Cropper
                src={imageSrc}
                style={{ height: "100%", width: "100%" }}
                zoomTo={0.5}
                initialAspectRatio={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                guides={true}
                onInitialized={(instance) => setCropper(instance)}
              />
            </Box>
          )}
        </ModalBody>
        <ModalFooter
          mt="4"
          borderTopWidth={"1px"}
          borderTopColor={defaultGreyColor}
        >
          {cropImageFlag ? (
            <Flex>
              <Button
                colorScheme="green"
                size="sm"
                mr="3"
                onClick={handleCropImageClick}
                isDisabled={uploadLoading}
                isLoading={uploadLoading}
              >
                Crop
              </Button>
              <Button
                size="sm"
                colorScheme="red"
                onClick={onModalClose}
                isDisabled={uploadLoading}
              >
                Cancel
              </Button>
            </Flex>
          ) : (
            <Flex>
              <Button
                colorScheme="green"
                size="sm"
                mr="3"
                onClick={compressImageSize}
                isLoading={loading}
                isDisabled={loading}
              >
                Yes
              </Button>
              <Button
                colorScheme="red"
                size="sm"
                onClick={onModalClose}
                isDisabled={loading}
              >
                No
              </Button>
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ImageCompressorModal;
